.t {
  transform: none;
}
[play-wide] {
  position: relative;
  width: 100%;
  height: 100%;
}
[play-wide] .wrap {
  position: relative;
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}
[play-wide] .wrap .video-wrap {
  width: 100%;
  height: 100%;
  position: relative;
}
[play-wide] .wrap .video-wrap img {
  display: block;
}
[play-wide] .wrap .video-wrap video,
[play-wide] .wrap .video-wrap img {
  width: 100%;
  position: relative;
}
[play-wide] .wrap .video-wrap p {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  cursor: pointer;
}
@media (max-width: 767px) and (max-height: 515px) {
  [play-wide] .wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }
  [play-wide] .wrap .video-wrap video,
  [play-wide] .wrap .video-wrap img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (min-width: 768px) {
  [play-wide] .wrap .play {
    width: 4.25rem;
    height: 4.25rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) and (max-height: 768px) {
  [play-wide] .wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }
  [play-wide] .wrap .video-wrap video,
  [play-wide] .wrap .video-wrap img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (min-width: 1024px) {
  [play-wide] .wrap .controls {
    right: 2.25rem;
    bottom: 1.5rem;
    left: auto;
    top: auto;
  }
  [play-wide] .wrap .controls a {
    width: 2rem;
    height: 2rem;
  }
  [play-wide] .wrap .controls a.mute {
    margin-right: 1.5rem;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) and (max-height: 1024px) {
  [play-wide] .wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }
  [play-wide] .wrap .video-wrap video,
  [play-wide] .wrap .video-wrap img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (min-width: 1440px) {
  [play-wide] .wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }
  [play-wide] .wrap .video-wrap video,
  [play-wide] .wrap .video-wrap img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
