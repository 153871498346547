.t {
  transform: none;
}
[play-shorts] {
  position: relative;
  width: 100%;
  height: 100%;
}
[play-shorts] .content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
[play-shorts] .content .default {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-color: #000;
}
[play-shorts] .content [cdn-video],
[play-shorts] .content [cdn-img] {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
[play-shorts] .content .video-controls {
  position: absolute;
  left: 1.125rem;
  top: 1.75rem;
  right: auto;
  bottom: auto;
  z-index: 1;
}
[play-shorts] .content .video-controls a {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1.25rem;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity 0.3s ease;
}
.no-touch [play-shorts] .content .video-controls a:hover {
  opacity: 0.6;
}
[play-shorts] .content .video-controls a.screen-btn {
  background-image: url('/img/common/ic-white-full-screen.png');
}
[play-shorts] .content .video-controls a.screen-btn.open {
  background-image: url('/img/common/ic-white-full-screen-close.png');
}
[play-shorts] .content .video-controls a.play-btn {
  background-image: url('/img/common/ic-white-pause.png');
}
[play-shorts] .content .video-controls a.play-btn.pause {
  background-image: url('/img/common/ic-white-play.png');
}
[play-shorts] .content .video-controls a.sound-btn {
  background-image: url('/img/common/ic-white-sound.png');
}
[play-shorts] .content .video-controls a.sound-btn.mute {
  background-image: url('/img/common/ic-white-mute.png');
}
[play-shorts] .remote {
  position: absolute;
  left: 0rem;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translateY(-50%);
  width: 100%;
  height: 70%;
}
[play-shorts] .remote a {
  height: 100%;
  display: inline-block;
  transition: opacity 0.3s ease;
}
.no-touch [play-shorts] .remote a:hover {
  opacity: 0.6;
}
[play-shorts] .remote a.in-prev {
  width: 30%;
}
[play-shorts] .remote a.in-next {
  width: 70%;
}
[play-shorts] .controls {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  padding-top: 0rem;
  padding-right: 1.125rem;
  padding-bottom: 5.375rem;
  padding-left: 1.125rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 23%, rgba(0, 0, 0, 0));
}
[play-shorts] .controls .bar {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 1.625rem;
  margin-left: 0.125rem;
  box-sizing: border-box;
  cursor: pointer;
  flex-grow: 1;
}
[play-shorts] .controls .bar:before {
  content: '';
  display: block;
  width: 100%;
  height: 0.125rem;
  background-color: rgba(255, 255, 255, 0.3);
}
[play-shorts] .controls .bar:first-of-type {
  margin-left: 0rem;
}
[play-shorts] .controls .bar .progress-bar {
  position: absolute;
  left: 0rem;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translateY(-50%);
  width: 0rem;
  height: 0.125rem;
  background-color: #fff;
  pointer-events: none;
}
[play-shorts] .controls .bar .progress-bar.easing {
  transition: width 0.3s linear;
}
@media (min-width: 768px) {
  [play-shorts] .content .video-controls {
    left: 1.625rem;
    top: 3.375rem;
    right: auto;
    bottom: auto;
  }
  [play-shorts] .content .video-controls a {
    width: 2rem;
    height: 2rem;
    margin-right: 1.625rem;
  }
  [play-shorts] .remote {
    height: auto;
    width: 100%;
  }
  [play-shorts] .remote a {
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
  }
  [play-shorts] .remote a.in-prev {
    width: 2.1875rem;
    height: 2.1875rem;
    left: 0.875rem;
    background-image: url('/img/common/btn-inner-prev.png');
  }
  [play-shorts] .remote a.in-next {
    width: 2.1875rem;
    height: 2.1875rem;
    right: 0.875rem;
    background-image: url('/img/common/btn-inner-next.png');
  }
  [play-shorts] .controls {
    padding-top: 0rem;
    padding-right: 1.625rem;
    padding-bottom: 15rem;
    padding-left: 1.625rem;
  }
  [play-shorts] .controls .bar {
    height: 3.375rem;
  }
  [play-shorts] .controls .bar:before {
    content: '';
    display: block;
    width: 100%;
    height: 0.125rem;
    background-color: rgba(255, 255, 255, 0.3);
  }
}
@media (min-width: 768px) and (min-width: 768px) and (max-height: 768px) {
  [play-shorts] .content .video-controls {
    left: 1.25rem;
    top: 2.625rem;
    right: auto;
    bottom: auto;
  }
  [play-shorts] .content .video-controls a {
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 1.375rem;
  }
  [play-shorts] .remote a.in-prev {
    width: 1.75rem;
    height: 1.75rem;
    left: 0.625rem;
  }
  [play-shorts] .remote a.in-next {
    width: 1.75rem;
    height: 1.75rem;
    right: 0.625rem;
  }
  [play-shorts] .controls {
    padding-top: 0rem;
    padding-right: 1.25rem;
    padding-bottom: 11.875rem;
    padding-left: 1.25rem;
  }
  [play-shorts] .controls .bar {
    height: 2.625rem;
  }
  [play-shorts] .controls .bar:before {
    height: 0.125rem;
  }
}
@media (min-width: 1024px) {
  [play-shorts] .content .video-controls {
    left: 2.125rem;
    top: 4.4375rem;
    right: auto;
    bottom: auto;
  }
  [play-shorts] .content .video-controls a {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 2.25rem;
  }
  [play-shorts] .remote a.in-prev {
    width: 2.9375rem;
    height: 2.9375rem;
    left: 0.875rem;
  }
  [play-shorts] .remote a.in-next {
    width: 2.9375rem;
    height: 2.9375rem;
    right: 0.875rem;
  }
  [play-shorts] .controls {
    padding-top: 0rem;
    padding-right: 2.125rem;
    padding-bottom: 20rem;
    padding-left: 2.125rem;
  }
  [play-shorts] .controls .bar {
    height: 4.4375rem;
  }
  [play-shorts] .controls .bar:before {
    height: 0.1875rem;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) and (max-height: 1024px) {
  [play-shorts] .content .video-controls {
    left: 1.25rem;
    top: 2.625rem;
    right: auto;
    bottom: auto;
  }
  [play-shorts] .content .video-controls a {
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 1.375rem;
  }
  [play-shorts] .remote a.in-prev {
    width: 1.5rem;
    height: 1.5rem;
    left: 0.625rem;
  }
  [play-shorts] .remote a.in-next {
    width: 1.5rem;
    height: 1.5rem;
    right: 0.625rem;
  }
  [play-shorts] .controls {
    padding-top: 0rem;
    padding-right: 1.25rem;
    padding-bottom: 11.875rem;
    padding-left: 1.25rem;
  }
  [play-shorts] .controls .bar {
    height: 2.625rem;
  }
  [play-shorts] .controls .bar:before {
    height: 0.125rem;
  }
}
@media (min-width: 1440px) {
  [play-shorts] .content .video-controls {
    left: 1.25rem;
    top: 2.625rem;
    right: auto;
    bottom: auto;
  }
  [play-shorts] .content .video-controls a {
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 1.375rem;
  }
  [play-shorts] .remote a.in-prev {
    width: 1.75rem;
    height: 1.75rem;
    left: 0.875rem;
  }
  [play-shorts] .remote a.in-next {
    width: 1.75rem;
    height: 1.75rem;
    right: 0.875rem;
  }
  [play-shorts] .controls {
    padding-top: 0rem;
    padding-right: 1.25rem;
    padding-bottom: 11.875rem;
    padding-left: 1.25rem;
  }
  [play-shorts] .controls .bar {
    height: 2.625rem;
  }
  [play-shorts] .controls .bar:before {
    height: 0.125rem;
  }
}
@media (min-width: 1600px) {
  [play-shorts] .content .video-controls {
    left: 1.625rem;
    top: 3.4375rem;
    right: auto;
    bottom: auto;
  }
  [play-shorts] .content .video-controls a {
    width: 2.0625rem;
    height: 2.0625rem;
    margin-right: 1.875rem;
  }
  [play-shorts] .remote a.in-prev {
    width: 2.25rem;
    height: 2.25rem;
    left: 1.125rem;
  }
  [play-shorts] .remote a.in-next {
    width: 2.25rem;
    height: 2.25rem;
    right: 1.125rem;
  }
  [play-shorts] .controls {
    padding-top: 0rem;
    padding-right: 1.625rem;
    padding-bottom: 15.875rem;
    padding-left: 1.625rem;
  }
  [play-shorts] .controls .bar {
    height: 3.4375rem;
  }
  [play-shorts] .controls .bar:before {
    height: 0.1875rem;
  }
}
